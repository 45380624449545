/* eslint-disable @next/next/no-img-element */
import Script from 'next/script';

// components
import { Container } from 'components/Grid';

// styles
import {
  CustomersLogosWrapper,
  LogosList,
  LogoCapterra,
  LogoG2,
} from './BadgeRatings.styles';

export function BadgeRatings() {
  return (
    <>
      <Script id="vwo-setup" strategy="afterInteractive">
        {`
        (function(a,b,c,d){window.fetch("https://www.g2.com/products/shipperguide/rating_schema.json").then(e=>e.json()).then(f=>{c=a.createElement(b);c.type="application/ld+json";c.text=JSON.stringify(f);d=a.getElementsByTagName(b)[0];d.parentNode.insertBefore(c,d);});})(document,"script");
      `}
      </Script>
      <CustomersLogosWrapper>
        <Container>
          <LogosList>
            <LogoG2
              href="https://www.g2.com/products/shipperguide/reviews?utm_source=review-widget"
              title="Read reviews of ShipperGuide on G2"
              target="_blank"
            >
              <img
                alt="Read ShipperGuide reviews on G2"
                src={`https://www.g2.com/products/shipperguide/widgets/stars?color=gray&amp;type=read`}
                width="230"
                height="75"
              />
            </LogoG2>
            <LogoCapterra
              href="https://www.capterra.com/reviews/207128/Loadsmart?utm_source=vendor&amp;utm_medium=badge&amp;utm_campaign=capterra_reviews_badge"
              target="_blank"
            >
              <img
                alt="Read ShipperGuide reviews on Capterra"
                src={`https://assets.capterra.com/badge/42ca3df3b2ea0bc3c839624d47d7d8c7.svg?v=2148568&amp;p=207128`}
                width="230"
                height="75"
              />
            </LogoCapterra>
          </LogosList>
        </Container>
      </CustomersLogosWrapper>
    </>
  );
}
