import styled, { css } from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

//components
import { InvertedSection } from '@/components/Grid';

export const HubspotFormTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: center;

  ${screen.md} {
    font-size: 28px;
    line-height: 40px;
  }

  ${screen.lg} {
    font-size: 38px;
    line-height: 50px;
  }

  ${screen.xxlg} {
    font-size: 48px;
    line-height: 72px;
  }
`;

const descriptionTextStyle = css`
  color: rgb(${getToken('color-primary-100')});
  font-family: ${getToken('platform-font-default')};
  font-weight: ${getToken('font-weight-regular')};
  font-size: 12px;
  line-height: 22px;
  text-align: center;

  ${screen.md} {
    font-size: 12px;
    line-height: 22px;
  }

  ${screen.lg} {
    font-size: 16px;
    line-height: 30px;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 32px;
  }
`;

export const HubspotFormDescription = styled.p`
  ${descriptionTextStyle};
`;

export const TitleContainer = styled.header`
  &:is(.Title):has(+ div > #integration-hubspot > div) {
    display: none;
  }

  p {
    text-align: start;
    font-family: ${getToken('font-family-default')};
    color: rgb(${getToken('color-primary-100')});
    font-weight: ${getToken('font-weight-regular')};

    font-size: 14px;
    line-height: 26px;

    ${screen.md} {
      font-size: 20px;
      line-height: 32px;
    }
  }
`;

export const FormContainer = styled.article``;

const Label = css`
  color: rgb(${getToken('color-text-tertiary')});
  font-family: ${getToken('platform-font-default')};
  font-weight: ${getToken('font-weight-regular')};
  width: 100%;
  margin: 0;

  font-size: 12px;
  line-height: 18px;
  text-align: left;

  ${screen.md} {
    font-size: 14px;
    line-height: 21px;
  }
`;

const Input = css`
  display: block;
  box-sizing: border-box;
  width: 100% !important;
  min-width: 275px;
  height: 48px;
  margin: 6px 0;
  padding: 8px 10px;

  font-family: ${getToken('platform-font-default')};
  font-weight: ${getToken('font-weight-regular')};
  font-size: 14px;
  line-height: 21px;

  color: rgb(${getToken('color-neutral-60')});
  background-color: rgb(${getToken('color-background-primary')});
  border: 1px solid rgb(${getToken('color-neutral-40')});
  border-radius: 4px;
  outline: 0;

  appearance: none;

  &::placeholder {
    color: rgb(${getToken('color-neutral-60')});
  }

  ${screen.md} {
    min-width: 477px;
  }

  ${screen.lg} {
    min-width: 380px;
  }

  ${screen.xxlg} {
    min-width: 477px;
  }
`;

const Button = css`
  width: 100%;
  height: 44px;
  margin: 10px 0;

  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;

  color: rgb(${getToken('color-text-primary')});
  background-color: rgb(${getToken('color-brand')});
  border: 0;
  border-radius: 4px;
  cursor: pointer;
`;

const ActionsWrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Fieldset = css`
  max-width: initial;
`;

const Checkbox = css`
  width: 100% !important;
  margin: 4px auto;

  ${screen.md} {
    width: 50% !important;
  }

  label {
    display: flex;
    align-items: center;
    padding: 8px;

    cursor: pointer;

    span {
      display: inline-flex;
      padding: 0 8px;
    }

    input {
      cursor: pointer;
    }
  }

  &:hover,
  &:focus-within {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 4px;
  }
`;

const SubmittedMessage = css`
  &:before {
    background: url(/images/shipperguide/demo/submit-confirmed.svg) no-repeat center
    top;
    background-size: 128px 128px;
    display: flex;
    align-items: center;
    justify-content: center
    width: 128px;
    height: 128px;
    content: '';
    margin: 0 0 62px;
  }
`;

export const Wrapper = styled.div`
  & .hs-custom-style {
    background-color: black !important;
  }

  & .hs-form fieldset {
    ${Fieldset};
  }

  & .hs-form-field {
    display: flex;
    flex-direction: column;
  }

  & .hs-form fieldset.form-columns-2 .hs-form-field {
    width: 100% !important;
  }

  & .hs-form-field .input {
    margin: 0 0 12px !important;

    ${screen.md} {
      margin: 0 0 20px !important;
    }

    ${screen.lg} {
      margin: 0 0 20px !important;
    }

    ${screen.xxlg} {
      margin: 0 0 20px !important;
    }
  }

  & .hs-button-reset,
  & .hs-form .hs-button {
    ${Button};
  }

  & .hs-form label {
    ${Label};
  }

  & .hs-error-msgs {
    padding: 0;
    margin: -10px 0 12px;
    list-style-type: none;

    label {
      color: rgb(${getToken('color-text-error')});
    }

    ${screen.md} {
      margin: -20px 0 20px;
    }
  }

  & .actions {
    ${ActionsWrapper};
  }

  & .hs-form select {
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
      no-repeat 98% 50%;
  }

  & .hs-form input[type='text'],
  & .hs-form input[type='email'],
  & .hs-form input[type='number'],
  & .hs-form select,
  & .hs-form input[type='tel'] {
    ${Input};
  }

  & .submitted-message {
    ${descriptionTextStyle};
    ${SubmittedMessage};
  }

  ul.inputs-list.multi-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    list-style: none;
  }

  & .hs-form-checkbox {
    ${Checkbox};
  }
`;

export const Section = styled(InvertedSection)`
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background-color: rgb(${getToken('color-background-primary')});
  padding: 20px 20px 30px;

  ${screen.md} {
    max-width: 597px;
    min-height: 751px;
    padding: 30px 30px 40px;
  }

  ${screen.lg} {
    max-width: 460px;
    min-height: 711px;
  }

  ${screen.xxlg} {
    padding: 50px 40px 60px;
    max-width: 597px;
    min-height: 751px;
  }
`;
